var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    { attrs: { title: "Doughnut Chart" } },
    [
      _c("div", { staticClass: "pie-text text-center" }, [
        _c("h2", { staticClass: "font-weight-bolder" }, [_vm._v(" $23,994 ")]),
        _c("span", { staticClass: "font-weight-bold" }, [_vm._v("Total")])
      ]),
      _c("app-echart-doughnut", { attrs: { series: _vm.series } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }