var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    { attrs: { title: "New Technologies Data" } },
    [
      _c(
        "div",
        { staticClass: "switch-button float-sm-right" },
        [
          _c(
            "b-form-group",
            [
              _c("b-form-radio-group", {
                attrs: {
                  id: "btn-radios-1",
                  "button-variant": "outline-primary",
                  options: _vm.radioOption,
                  buttons: "",
                  size: "sm",
                  name: "radios-btn-default"
                },
                model: {
                  value: _vm.selected,
                  callback: function($$v) {
                    _vm.selected = $$v
                  },
                  expression: "selected"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("app-echart-scatter", { attrs: { "option-data": _vm.option } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }